/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.a-primary-color{
    color:  #B79086;
}

.a-secondary-color{
    color:  #577778;
}

.green-snackbar {
    --mdc-snackbar-container-color: #E1F8F0;
    --mdc-snackbar-supporting-text-color: #212B36;
    border-left: 6px solid #34D399;
}
::-ms-reveal {
    display: none;
    /*elimina el show password de microsoft edge para poder usar el de luna nueva*/
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
    z-index: 10 !important;
}

.iti-mobile .iti--container {
    height: 25% !important;
    top: 57% !important;    
}

.iti__country-list{
    border-radius: 5px;
}